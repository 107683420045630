import React, { useState } from "react"
import Swiper from "react-id-swiper"

const ManipulatingSwiper = props => {
  const [swiper, updateSwiper] = useState(null)

  const params = {
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    centeredSlides: true,
    slidesPerView: 3,
    spaceBetween: 30,
    loop: true,

    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2.1,
      },
      375: {
        slidesPerView: 1.2,
      },
    },
  }

  //   const goNext = () => {
  //     if (swiper !== null) {
  //       swiper.slideNext()
  //     }
  //   }

  //   const goPrev = () => {
  //     if (swiper !== null) {
  //       swiper.slidePrev()
  //     }
  //   }

  return (
    <div>
      <Swiper getSwiper={updateSwiper} {...params}>
        {props.children.map((x,key) => (
          <div
            key={key}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {x}
          </div>
        ))}
      </Swiper>
      {/* <button onClick={goPrev}>Prev</button>
      <button onClick={goNext}>Next</button> */}
    </div>
  )
}

export default ManipulatingSwiper
